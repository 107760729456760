.MuiDivider-root {
	height: 0px;
}
.clickable {
	color: #f3692a;
	cursor: pointer;
}
.title-text-style {
	color: #37474f;
	font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
	font-weight: 700;
	font-size: 22px;
}
.filter-wrap {
	margin: 15px 35px;
	display: flex;
	justify-content: space-between;
	@media screen and (max-width: '1024px') {
		margin: 20px 8px;
	}
}
.header-bg {
	background-color: #f4f2ff;
}
.float-wrap {
	.left {
		position: fixed;
		float: left;
		bottom: 0;
		left: 35px;
		@media screen and (max-width: '1024px') {
			left: 8px;
		}
		button {
			text-transform: capitalize;
			font-size: 13px;
			border-radius: 5px !important;
		}
	}
	.right {
		position: fixed;
		float: right;
		bottom: 0;
		right: 35px;
		@media screen and (max-width: '1024px') {
			right: 8px;
		}
		button {
			text-transform: capitalize;
			font-size: 13px;
			border-radius: 5px !important;
		}
	}
}
.table-wrap {
	margin: 15px 35px;
	@media screen and (max-width: '1024px') {
		margin: 20px 8px;
	}
	.tables {
		overflow-y: hidden;
		.table {
			display: inline-table;
			width: 100%;
		}
		.table-header {
			background: #f4f2ff;
			text-transform: uppercase;
			font-style: normal;
			font-weight: 600;
			line-height: 15px;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: #6e6893;
			border: 1px solid #f4f2ff;
			.cell {
				font-size: 11px;
			}
		}
		.table-header-bd {
			border-left: 4px solid #f4f2ff;
		}
		.table-header-bd-sm {
			border-left: 2px solid #f4f2ff;
		}
		.table-row {
			border: 1px solid transparent;
			.cell {
				border-left: none;
				border-right: none;
				border-top: none;
				color: #808080;
				border-bottom: 1px solid #e2e1e1;
			}
			.clickable {
				color: #f3692a;
				cursor: pointer;
			}
		}
		.table-mobi {
			border: none !important;
			.table-body-mobi {
				.table-row-mobi {
					.cell {
						border-left: none;
						border-right: none;
						border-top: none;
						color: #454e4c;
						border-bottom: 4px solid #e2e1e1;
						width: 50%;
						.clickable {
							color: #f3692a;
							cursor: pointer;
						}
					}
				}
			}
		}
		.paid-row {
			border-left: 4px solid blue;
		}
		.unpaid-row {
			border-left: 4px solid #f2682a;
		}
		.pagination {
			background: #f4f2ff;
		}
		.edit {
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 15px;
			text-decoration-line: underline;
			color: #00cdac;
			cursor: pointer;
		}
		.activate {
			display: flex;
			justify-content: space-around;
			.act {
				background: #70d8a0;
			}
			.act:hover {
				background: rgba(112, 216, 160, 0.7);
			}
			.deact {
				background: #ff6e37;
			}
			.deact:hover {
				background: rgba(255, 110, 55, 0.7);
			}

			button {
				cursor: pointer;
				width: 95px;
				display: flex;
				align-items: center;
				border-radius: 10px;
				border: none;
				color: white;
				padding: 3px 5px;
				.material-icons {
					font-size: 11px;
					padding-right: 5px;
				}
			}
		}
	}
}
.clean_paper {
	background-color: rgba(0, 0, 0, 0) !important;
	box-shadow: none !important;
	color: #232c39 !important;
	.title {
		color: #37474f;
		font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
		font-weight: 700;
		font-size: 22px;
	}
}
.admin_add_officer_btn {
	text-decoration: none;
	background: #232c39;
	color: white;
	padding: 4px 12px;
	border-radius: 6px;
}
.admin_officers_card_p {
	padding: 1% 4%;
	font-size: 1.2em;
}
.admin_metrics_card {
	margin: 3%;
	padding: 3%;
}
.sort_packages {
	text-decoration: none;
	background: #232c39;
	color: white;
	padding: 7px 12px;
	border-radius: 6px;
	width: 100px;
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
}

.variables-content {
	margin: 40px 0 0 20px;
}

.yaxislabel {
	font-size: 14pt;
}

.search_input {
	border-bottom: 1px solid gray !important;
}
.css-h4y409-MuiList-root {
	padding: 0 !important;
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
	border: none !important;
}
.css-9mgopn-MuiDivider-root {
	border: none !important;
}
.dashboard_drawer_logo {
	width: 100%;
	height: 24vh;
	background-color: #232c39 !important;
	background-image: url('../../assets/images/logo.png');
	background-size: 86% auto;
	background-position: center;
	background-repeat: no-repeat;
	box-shadow: none !important;
}

.dashboard_clear_drawer_logo {
	width: 100%;
	height: 24vh;
	background-color: #ffffff !important;
	background-image: url('../../assets/images/logo.png');
	background-size: 86% auto;
	background-position: center;
	background-repeat: no-repeat;
	box-shadow: none !important;
}

.dashboard_clear_drawer_inv {
	width: 70%;
	height: 17vh;
	background-color: #ffffff !important;
	background-image: url('../../assets/images/logo.png');
	background-size: 100% auto;
	background-position: center;
	background-repeat: no-repeat;
	box-shadow: none !important;
}

.dashboard_drawer_links {
	width: 100%;
	padding: 0;
	border-radius: 0 !important;
	box-shadow: none !important;
	// min-height: 64vh;
	background-color: #ffffff !important;
	outline: none !important;
	border: none !important;
	.links {
		text-decoration: none;
		color: white;
		background-color: red;
		border: none !important;
	}
}

.dash_notification_paper {
	width: 92%;
	padding: 4%;
	margin-bottom: 2vh;
}

.dashboard_profile_card {
	margin: 12%;
	margin-right: 4%;
	margin-bottom: 2%;
	box-shadow: 0 0 6px 0 gray !important;
	@media screen and (max-width: '1024px') {
		margin: 2%;
	}
}

.dashboard_legend_card {
	margin: 12%;
	margin-right: 4%;
	margin-top: 2%;
	box-shadow: 0 0 6px 0 gray !important;
	@media screen and (max-width: '1024px') {
		margin: 2%;
	}
}

.dashboard_recent_activity {
	margin: 9%;
	margin-left: 1%;
	box-shadow: 0 0 6px 0 gray !important;
	@media screen and (max-width: '1024px') {
		margin: 2%;
	}
}

.dash_recent_activity_pipe {
	font-weight: bold;
	color: gray;
	font-size: 2em;
	text-align: center;
}

.dash_recent_activity_0 {
	font-size: 1.2em;
	text-align: center;
	font-weight: bold;
}

.dash_recent_activity_txt {
	font-size: 1.2em;
}

.dash_pay_via_mpesa {
	background: darkgreen;
	color: white;
	font-size: 1.2em;
}

.align_icons {
	display: inline-flex;
	vertical-align: middle !important;
	padding: 5px 0;
}

.align_numbers {
	display: flex;
	flex-wrap: wrap;
}

.linear_progress {
	position: fixed;
	width: 100%;
	top: 0vh;
	left: 0;
	z-index: 1;
}

.pagination_btns {
	margin: 4px;
	font-size: 1.2em;
	outline: none;
	cursor: pointer;
	border: none;
	padding: 4px 10px;
	background: rgba(0, 0, 255, 0);
	color: rgb(0, 0, 255);
}

.pagination_btn_active {
	margin: 4px;
	font-size: 1.2em;
	outline: none;
	cursor: pointer;
	border: none;
	padding: 4px 10px;
	background: rgba(0, 0, 255, 0.1);
	color: rgb(0, 0, 255);
}

table,
td {
	// border: 1px solid black;
	// border-collapse: collapse;
	// padding-left: 25px;
}

.issued {
	th {
		border-radius: 8px;
		border: 1px solid white;
		background-color: #f07829;
		color: white;
	}
}

#single-invoice .issued th,
#single-invoice .inverted th {
	background-color: #f07829 !important;
}

.billed {
	th {
		text-align: left;
		color: #f07829;
	}
}

.line-height {
	line-height: 80px;
	padding-left: 5px;
}

.long-table {
	th {
		background-color: #eeecec;
		border: 1px solid white;
		border-radius: 8px;
		text-align: left;
		padding: 5px 8px;
	}
	font-size: 0.8em;
}

.long-table-height {
	line-height: 50px;
}

.long-table-pad {
	line-height: 30px;
}

.inverted {
	th {
		border: 1px solid white;
		border-radius: 8px;
		background-color: #f07829;
		color: white;
	}
}

.far-right {
	float: right;
}

input[type='radio']:checked:after {
	background-color: #ffa500;
}

input[type='radio']:active {
	background-color: #ffa500;
}
